.container {
  height: 100vh;
}

.background {
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  width: 200vw;
  height: 200vh;
  background-size: fill;
  background-repeat: repeat;
  background-position: center;
}

.promotion {
  transform: translateY(-175px);

  background-image: url('../../Assets/ResultPage/cardboard.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  @media (max-width: 768px) {
    transform: translateY(-225px);
  }
}

.logotype {
  position:absolute;
  z-index: 1;
  width: 120px;
  height: 72px;
}

.flower {
  max-width: 400px;
}

.title {
  display: flex;
  transform: rotate(-5deg);
  background: #FCCF60;
  box-shadow: 7px 7px 0px 0px #000; 
}

.title2 {
  display: flex;
  transform: rotate(-5deg);
  background: #46361A;
}

.actionPanel {
  transform: translate(-24px, -60px);

  @media screen and (max-width: 768px) {
    transform: translate(0px, -60px);
  }
}