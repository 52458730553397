.wrapper {
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-size: fill;
  background-repeat: repeat;
  background-position: center;
}

.topCurtain {
  position: absolute;
  z-index: 20;
  top: -120px;
  
  pointer-events: none;

  width: 100vw;
  height: 340px;
  background-image: url('../../Assets/ProblemPage/Base/top.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  @media screen and (max-width: 1024px) {
    top: -140px;
  }
}

.bottomCurtain {
  position: absolute;
  z-index: 20;
  bottom: 0;

  pointer-events: none;

  width: 100vw;
  height: 160px;
  background-image: url('../../Assets/TutorialPage/bottom.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media screen and (max-width: 1024px) {
    height: 80px;
  }
}

.bottomRightCurtain {
  position: absolute;
  z-index: 19;
  bottom: 0;
  right: 0;

  pointer-events: none;

  width: 641px;
  height: 227px;
  background-image: url('../../Assets/ProblemPage/Base/bottom\ right.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;

  @media screen and (max-width: 1024px) {
    width: 320.5px;
    height: 113.5px;
  }
}

.problemWrapper {
  position: absolute;
  z-index: 21;
  top: 0;
  left: 0;
  width: 100vw;
}

input:checked + label {
  transform: scale(1.05);
  filter: drop-shadow(0px 0px 15px #FCCF60);
  font-weight: bold;
}

.optionsWrapper {
  position: absolute;
  height: 200px;
}

.optionGridImage {
  max-height: calc((100vh - 300px) / 2);
}

.optionImage {
  // max-height: calc((100vh - 300px) / 6);
}

.options {
  z-index: 10;

  @keyframes slideIn-easeOutElastic {
    0% {
      transform: translateX(0%+100%);
    }
  
    16% {
      transform: translateX(-112.27%+100%);
    }
  
    28% {
      transform: translateX(-96.88%+100%);
    }
  
    44% {
      transform: translateX(-102.63%+100%);
    }
  
    59% {
      transform: translateX(-99.36%+100%);
    }
  
    73% {
      transform: translateX(-100.58%+100%);
    }
  
    88% {
      transform: translateX(-99.8%+100%);
    }
  
    100% {
      transform: translateX(-100%+100%);
    }
  }

  & > div {
    animation: slideIn-easeOutElastic 1.25s;
  }
}

.optionTitle {
  position: relative;
  bottom: 42px;
}

.bottomPuppet {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  transform: scale(1.5);
  animation: slideUp 1.5s ease-out forwards;

  @media screen and (max-width: 1024px) {
    transform: scale(1.0);
    left: -72px;
    bottom: -72px;
  }

  @keyframes slideUp {
    from {
      bottom: -100vh;
    }
    to {
      bottom: 0;
    }
  }
}

.problemTitle {
  transform: translateX(-200%);
	opacity: 0;
	animation: slideIn 1.5s ease-out forwards;

  @keyframes slideIn {
    20% {
      opacity: 0;
    }
    60% {
      transform: translateX(-5%);
    }
    75% {
      transform: translateX(2);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.button {
  z-index: 10;

  @keyframes slideIn-easeOutElastic {
    0% {
      transform: translateX(0%+100%);
    }
  
    16% {
      transform: translateX(-112.27%+100%);
    }
  
    28% {
      transform: translateX(-96.88%+100%);
    }
  
    44% {
      transform: translateX(-102.63%+100%);
    }
  
    59% {
      transform: translateX(-99.36%+100%);
    }
  
    73% {
      transform: translateX(-100.58%+100%);
    }
  
    88% {
      transform: translateX(-99.8%+100%);
    }
  
    100% {
      transform: translateX(-100%+100%);
    }
  }

  & > div {
    animation: slideIn-easeOutElastic 2s;
  }
}