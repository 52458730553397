.button {
  width: 200px;
  height: 64px;

  @media screen and (max-width: 768px) {
    width: 181.25px;
    height: 58px;
  }

  background-repeat: no-repeat;
  background-size: contain;

  &.primary {
    background-image: url( '../../Assets/Elements/tape-BTN.svg' );
  
    &:active {
      background-image: url( '../../Assets/Elements/tape-BTN-pressed.svg' );
    }
  }
  
  &.secondary {
    background-image: url( '../../Assets/Elements/tape-BTN-sec.svg' );
  
    &:active {
      background-image: url( '../../Assets/Elements/tape-BTN-sec-pressed.svg' );
    }
  }

  &.text {
    background: transparent;
    text-decoration: underline;

    &:active {
      background: transparent;
    }
  }
}

.disabled {
  width: 200px;
  height: 64px;

  @media screen and (max-width: 768px) {
    width: 181.25px;
    height: 58px;
  }

  pointer-events: none;
  
  background-image: url( '../../Assets/Elements/tape-BTN.svg' );
  background-repeat: no-repeat;
  background-size: contain;
}

.shadow {
  filter: drop-shadow(0 0 4px #777061);
}