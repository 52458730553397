.wrapper {
  position: relative;
  overflow: hidden;
}

.backCurtain {
  position: absolute;
  z-index: 0;
  bottom: 0;
  width: 305vh;
  min-width: 100vw;
  max-width: 205vh;
  height: 133vh;
}

.bottomCurtain {
  position: absolute;
  z-index: 20;
  bottom: 0;

  pointer-events: none;

  width: 100vw;
  height: 160px;
  background-image: url('../../Assets/TutorialPage/bottom.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media screen and (max-width: 1024px) {
    height: 80px;
  }

  @media screen and (max-height: 650px) {
    height: 120px;
  }

  @media screen and (max-height: 550px) {
    height: 100px;
  }
}

.image {
  animation: swing 1s infinite;

  @keyframes swing { 
    20% { transform: rotate(7.5deg); }
    40% { transform: rotate(-5deg); }
    60% { transform: rotate(2.5deg); }
    80% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
 } 
}

.caption {
  transform: translateX(-200%);
	opacity: 0;
	animation: slideIn 1.5s ease-out forwards;

  @keyframes slideIn {
    20% {
      opacity: 0;
    }
    60% {
      transform: translateX(-5%);
    }
    75% {
      transform: translateX(2);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}